var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-row',{staticClass:"mt-2 mx-2 pt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","dense":"","item-value":"id","item-text":"username","items":_vm.partnership_list,"label":"Select Partner"},model:{value:(_vm.selectedPartner),callback:function ($$v) {_vm.selectedPartner=$$v},expression:"selectedPartner"}})],1),_c('v-col',{staticClass:"text-left mt-0 pt-0",attrs:{"cols":"5","xs":"5"}},[_c('v-text-field',{attrs:{"dense":"","type":"date","label":"Start Date"},model:{value:(_vm.selectedStartDate),callback:function ($$v) {_vm.selectedStartDate=$$v},expression:"selectedStartDate"}})],1),_c('v-col',{staticClass:"text-right mt-0 pt-0",attrs:{"cols":"5","xs":"5"}},[_c('v-text-field',{attrs:{"dense":"","type":"date","label":"End Date"},model:{value:(_vm.selectedEndDate),callback:function ($$v) {_vm.selectedEndDate=$$v},expression:"selectedEndDate"}})],1),_c('v-col',{staticClass:"text-right mt-0 pt-0",attrs:{"cols":"2","xs":"2"}},[(_vm.loading)?_c('spinner',{staticClass:"text-end"}):_c('v-btn',{staticClass:"primary",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.getPartnerClients()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-search"}})],1)],1)],1),_c('v-card-title',{},[_c('span',{staticClass:"me-3"},[_vm._v("("+_vm._s(_vm.partner_clients.length)+") Partner Clients")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.partner_clients,"item-key":"id","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"cursor":"pointer"}},[_c('router-link',{staticClass:"d-block font-weight-semibold text--primary text-truncate",attrs:{"to":'/savings/clients/profile/account-settings/'+item.id}},[_vm._v(_vm._s(item.username))]),_c('small',[_vm._v(_vm._s(item.no_campaigns)+" (Saved: "+_vm._s(item.amount_saved_so_far)+") & "+_vm._s(item.no_loans)+" (Paid: "+_vm._s(item.amount_paid_so_far)+")")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.status]}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":'https://api.whatsapp.com/send?phone='+item.phone_number+'/&text=Greetings *'+item.username+'* 😃, i hope you are doing great 💫 today. %0a I\'m a PettyCash core team member, and am glad to bring you the following on your account. You currently have:- %0a *'+item.no_campaigns+'*, %0a *'+item.no_loans+'*, %0a *'+item.amount+' Current Savings*, %0a *'+item.amount_owed_so_far+' Debts.*',"target":"_blank"}},[_c('v-btn',{staticClass:"success",attrs:{"elevation":"6","icon":"","small":""}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-comments"}})],1)],1)]}},{key:"item.date_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_time)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }