<template>
  <v-card>
    <v-row class="mt-2 mx-2 pt-0">
      <v-col
        cols="12"
      >
        <v-select
          v-model="selectedPartner"
          outlined
          dense
          item-value="id"
          item-text="username"
          :items="partnership_list"
          label="Select Partner"
        ></v-select>
      </v-col>
      <v-col
        cols="5"
        xs="5"
        class="text-left mt-0 pt-0"
      >
        <v-text-field
          v-model="selectedStartDate"
          dense
          type="date"
          label="Start Date"
        ></v-text-field>
      </v-col>
      <v-col
        cols="5"
        xs="5"
        class="text-right mt-0 pt-0"
      >
        <v-text-field
          v-model="selectedEndDate"
          dense
          type="date"
          label="End Date"
        ></v-text-field>
      </v-col>
      <v-col
        cols="2"
        xs="2"
        class="text-right mt-0 pt-0"
      >
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn
          v-else
          icon small
          class="primary"
          @click="getPartnerClients()"
        >
          <font-awesome-icon icon="fa-solid fa-search"/>
        </v-btn>
      </v-col>
    </v-row>
    <v-card-title class="">
      <span class="me-3">({{ partner_clients.length }}) Partner Clients</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="partner_clients"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
          <!-- name -->
          <template #[`item.username`]="{item}">
            <div class="d-flex flex-column" style="cursor: pointer">
              <router-link :to="'/savings/clients/profile/account-settings/'+item.id" class="d-block font-weight-semibold text--primary text-truncate">{{ item.username }}</router-link>
              <small>{{ item.no_campaigns }} (Saved: {{ item.amount_saved_so_far }})  & {{ item.no_loans }} (Paid: {{ item.amount_paid_so_far }})</small>
            </div>
          </template>
          <!-- status -->
          <template #[`item.status`]="{item}">
            <v-chip
              small
              :color="statusColor[item.status]"
              class="font-weight-medium"
            >
              {{ item.status }}
            </v-chip>
          </template>

          <template #[`item.phone_number`]="{item}">
            <a 
                :href="'https://api.whatsapp.com/send?phone='+item.phone_number+'/&text=Greetings *'+item.username+'* 😃, i hope you are doing great 💫 today. %0a I\'m a PettyCash core team member, and am glad to bring you the following on your account. You currently have:- %0a *'+item.no_campaigns+'*, %0a *'+item.no_loans+'*, %0a *'+item.amount+' Current Savings*, %0a *'+item.amount_owed_so_far+' Debts.*'" 
                target="_blank"
                style="text-decoration: none"
            >
              <v-btn class="success" elevation="6" icon small>
                <font-awesome-icon icon="fa-solid fa-comments"/>
              </v-btn>
            </a>
          </template>
          <template #[`item.date_time`]="{item}">
            {{ item.date_time }}
          </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Active: 'primary',
      Validated: 'success',
      Inactive: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      switch1: true,
      headers: [
      { text: 'Name', value: 'username' },
        { text: 'Principal Campaign', value: 'amount' },
        { text: 'Loan Target', value: 'amount_owed_so_far' },
        { text: 'Savings Target', value: 'amount_savings_target_so_far' },
        { text: 'Status', value: 'status' },
        { text: 'Chat', value: 'phone_number' },
        { text: 'Date/Time', value: 'date_time' },
      ],
      partner_clients: [],
      statusColor,
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,
      selectedPartner: null,
      partnership_list: ["All"],

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Savings | List of Clients"
      this.getPartners()
  },

  methods:{
    getPartners(){
      axios
        .get('/api/v1/manager/get/partners/')
        .then(response => {
          this.partnership_list = response.data  // get the data and fill into campaigns
          console.log(this.partnership_list)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async getPartnerClients(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      if (!this.selectedPartner|| this.selectedPartner === ''){
        this.selectedPartner = 'All'
      }
      this.loading = true
      console.log(this.loading)
      await axios
        .get('/api/v1/manager/get/partner_clients/'+this.selectedPartner+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.partner_clients = response.data  // get the data and fill into campaigns
          console.log(this.partner_clients)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },

  },
}
</script>
